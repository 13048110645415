import React from 'react';
import content1 from './images/content1.jpg';
import feature2 from './images/feature2.jpg';
import pricing from './images/pricing.jpg';
import bluecross from './images/bluecross.png';
import aetna from './images/aetna.png';
import uhc from './images/uhc.png';

export const Banner00DataSource = {
  wrapper: { className: 'banner0 kiyvlqeglac-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper kiyy6n1ou5-editor_css' },
  title: {
    className: 'banner0-title kiyy432xptm-editor_css',
    children: (
      <span>
        <p>
          Tangerine Health<br />
        </p>
      </span>
    ),
  },
  content: {
    className: 'banner0-content kiyy6kxt5uf-editor_css',
    children: (
      <span>
        <span>
          <p>
            Virtual Primary Care<br />
          </p>
        </span>
      </span>
    ),
  },
  button: {
    className: 'banner0-button kiyyivcoc2-editor_css',
    children: (
      <span>
        <span>
          <p>Register</p>
        </span>
      </span>
    ),
    type: 'primary',
    href: 'https://www.tangerinehealth.co/app/register',
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: { children: content1 },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title kiz3l1ea5vo-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>
              A modern, innovative care to meet today's challenges<br />
            </p>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content kiz3oeq99s6-editor_css',
    children: (
      <span>
        <p>
          Take care of your health with affordable, convenient, and quality care<br />
        </p>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: { children: feature2 },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title kiz3mgzfoh-editor_css',
    children: (
      <span>
        <p>
          Dedicated doctor to manage your condition<br />
        </p>
      </span>
    ),
  },
  content: {
    className: 'content2-content kiz3o7kjp9o-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <div>
                <span>
                  We will be your dedicated primary care provider to manage your
                  blood pressure, cholesterol or diabetes completely online{' '}
                  <br />
                </span>
              </div>
            </span>
          </span>
        </span>
        <div>
          <p>
            <br />
          </p>
        </div>
        <span>
          <span>
            <span>
              <div>
                <span />
              </div>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: { children: pricing },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title kiz3myh9q1u-editor_css',
    children: (
      <span>
        <p>
          Receive full spectrum care from anywhere<br />
        </p>
      </span>
    ),
  },
  content: {
    className: 'content1-content kiz3nuvuzu-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>
                We will manage your condition, order labs and refer you to an
                in-network specialist when required. We will order and adjust
                your scripts, explain your blood work, and give the time you
                need to understand your condition.<br />
              </p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              We Accept Health Insurance<br />
            </p>
          </span>
        ),
        className: 'title-h1 kiz4nlkyti6-editor_css',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: bluecross },
          content: {
            children: (
              <span>
                <p>
                  BlueCross BlueShield<br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              aetna,
          },
          content: {
            children: (
              <span>
                <p>Aetna</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: uhc },
          content: {
            children: (
              <span>
                <p>
                  United Health Care<br />
                </p>
              </span>
            ),
          },
        },
      },
      // {
      //   name: 'block3',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
      //     },
      //     content: { children: 'Ant Motion' },
      //   },
      // },
      // {
      //   name: 'block4',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
      //     },
      //     content: { children: 'Ant Design' },
      //   },
      // },
      // {
      //   name: 'block5',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
      //     },
      //     content: { children: 'Ant Motion' },
      //   },
      // },
      // {
      //   name: 'block6',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
      //     },
      //     content: { children: 'Ant Design' },
      //   },
      // },
      // {
      //   name: 'block7',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
      //     },
      //     content: { children: 'Ant Motion' },
      //   },
      // },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          ©2021{' '}
          <a href="https://www.tangerinehealth.co/">Tangerine Health Inc.</a>{' '}
          All Rights Reserved
        </span>
      </span>
    ),
  },
};
